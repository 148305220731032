import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


import playstore from '../../public/assets/vendors/vendor-play-store.png'
import appsstore from '../../public/assets/vendors/vendor-app-store.png'
import socmedFB from '../../public/assets/share-socmed/fb.png'
import socmedIG from '../../public/assets/share-socmed/ig.png'
import socmedWA from '../../public/assets/share-socmed/wa.png'
import socmedX from '../../public/assets/share-socmed/x.png'
import socmedTg from '../../public/assets/share-socmed/tg.png'
import socmedLi from '../../public/assets/share-socmed/li.png'
import hiringArrow from '../../public/assets/hiring-process/arrow.png'
import hiring1 from '../../public/assets/hiring-process/1.png'
import hiring2 from '../../public/assets/hiring-process/2.png'
import hiring3 from '../../public/assets/hiring-process/3.png'
import hiring4 from '../../public/assets/hiring-process/4.png'
import hiring5 from '../../public/assets/hiring-process/5.png'
import hiring6 from '../../public/assets/hiring-process/6.png'


import bxLogo from '../../public/assets/officials/bx-logo.png'
import bxLogoWhite from '../../public/assets/officials/bx-logo-white-2.png'
import flagID from '../../public/assets/countries/ID.png'
import flagEN from '../../public/assets/countries/EN.png'
import ornamentChickentFoot from '../../public/assets/ornaments/ornament-chickend-foot.png'
import ornamentChickentHead from '../../public/assets/ornaments/ornament-chicken-head.png'
import ornamentChickentSide from '../../public/assets/ornaments/ornament-chicken-side.png'



import founderBgCard from '../../public/assets/founders/founder-bg-card.png'
import CTO from '../../public/assets/founders/cto.png'
import CEO from '../../public/assets/founders/ceo.png'
import COO from '../../public/assets/founders/coo.png'


import stag from '../../public/assets/icon/stag.svg'
import stagTurun from '../../public/assets/icon/stag_turun.svg'
import stagNaik from '../../public/assets/icon/stag_naik.svg'
import naik from '../../public/assets/icon/naik.svg'
import turun from '../../public/assets/icon/turun.svg'


// karir 
import karirAsset from "../../public/assets/util-karir.png";
import lifeAtBx1 from "../../public/assets/life-at-bx/1.png";
import lifeAtBx2 from "../../public/assets/life-at-bx/2.png";
import lifeAtBx3 from "../../public/assets/life-at-bx/3.png";
import lifeAtBx4 from "../../public/assets/life-at-bx/4.png";
import lifeAtBx5 from "../../public/assets/life-at-bx/5.png";
import lifeAtBx6 from "../../public/assets/life-at-bx/6.png";
import lifeAtBx7 from "../../public/assets/life-at-bx/7.png";

// UPDATE harga ayam 
import priceTag from "../../public/assets/update-harga-ayam/price-tag.png";
import priceTagBlur from "../../public/assets/update-harga-ayam/price-tag-blur.png";
import arrowUp from "../../public/assets/update-harga-ayam/arrow-up.png";
import chickenOrnament from "../../public/assets/update-harga-ayam/chicken.png";



export function useAssets() {
  return {
    playstore,
    appsstore,
    bxLogo,
    bxLogoWhite,
    flagID,
    flagEN,
    ornamentChickentFoot,
    ornamentChickentHead,
    ornamentChickentSide,
    founderBgCard,
    CTO,
    CEO,
    COO,
    stag,
    stagNaik,
    stagTurun,
    naik,
    turun,
    karirAsset,
    lifeAtBx1,
    lifeAtBx2,
    lifeAtBx3,
    lifeAtBx4,
    lifeAtBx5,
    lifeAtBx6,
    lifeAtBx7,
    priceTag,
    priceTagBlur,
    arrowUp,
    chickenOrnament,
    socmedFB
    , socmedIG
    , socmedWA
    , socmedX
    , hiringArrow
    , hiring1
    , hiring2
    , hiring3
    , hiring4
    , hiring5
    , hiring6
    , socmedTg
    , socmedLi
  }
}


export function useHref() {
  return {
    playstore: 'https://play.google.com/store/apps/details?id=com.broilerx.app',
    instagram: 'https://www.instagram.com/broilerxcom/',
    instagramLifeAtBx: 'https://www.instagram.com/lifeatbroilerx/',
    tiktok: 'https://www.tiktok.com/@broilerx',
    facebook: 'https://www.facebook.com/broilerxcom/',
    linkedin: 'https://www.linkedin.com/company/broilerxcom',
    youtube: 'https://www.youtube.com/@broilerx',
    appsstore: 'https://apps.apple.com/id/app/broilerx-app-poultry-partner/id6476918363'
  }
}


type Grouped<T> = { [key: string]: T[] };

export function groupBy<T>(array: T[], keyFn: (item: T) => string): Grouped<T> {
  return array.reduce((result: Grouped<T>, item: T) => {
    const key = keyFn(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
}